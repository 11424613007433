<template>
  <div>
    <SimpleTable v-bind="$attrs" :items="items" :fields="fields"></SimpleTable>
    <b-button class="mr-2" variant="primary" @click="add()">Add</b-button>
  </div>
</template>

<script>
import SimpleTable from '@/components/Table/Simple';
export default {
  name: 'PurchaseLimits',
  components: {
    SimpleTable
  },

  data() {
    return {};
  },
  props: {
    items: {
      type: Array
    },
    fields: {
      type: Array
    }
  },
  methods: {
    add() {
      this.items.push();
    },
    init() {
      // console.log(this.$route);
    }
  },

  computed: {
    // ...mapState({
    //   baseConfig: (state) => state.baseconfig.baseconfig
    // })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
